import React, { StyleHTMLAttributes } from "react";

const LOGO = "/static/logo.png";
const LOGO_WHITE = "/static/logo_branca.png";

interface LogoProps extends StyleHTMLAttributes<HTMLImageElement>{
    isBlank?: boolean;
}
function Logo({ isBlank, ...rest }: LogoProps) {
  return <img height={48} src={isBlank ? LOGO_WHITE : LOGO} alt="Logo da Aplicação" {...rest} />;
}

export default Logo;
