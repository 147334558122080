import { ToastError } from "../utils";
import axios, { AxiosRequestConfig } from "axios";

const ERRO_INTERNO_SERVIDOR = "Erro interno do servidor.";

function fnError(error: string): void {
  ToastError(error);
}

export default async function fetchRedux(
  route: string,
  method: string,
  formData: FormData | object | null,
  onSuccess: (response: any) => void,
  onWait: Function | null,
  onError: Function = fnError
  ) {
  let fnExec;
  let innerFormData = formData;
  let innerRoute = route;
  switch (method) {
    case "POST":
      fnExec = axios.post;
      break;
    case "GET":
    default:
      fnExec = axios.get;
      innerRoute =
        formData !== null ? `${route}?${formData.toString()}` : route;
      break;
    case "PUT":
      fnExec = axios.put;
      break;
    case "PATCH":
      fnExec = axios.patch;
      break;
    case "DELETE":
      fnExec = axios.delete;
      innerFormData = { data: formData };
      break;
  }
  //
  if (onWait) onWait(true);
  //
  try {
    const resp = await fnExec(innerRoute, innerFormData as AxiosRequestConfig);
    if (resp.status !== 200) {
      onError(ERRO_INTERNO_SERVIDOR);
      return false;
    }
    if (resp.data.erro) {
      onError(resp.data.erro);
      return false;
    }
    // Deu tudo certo execute a funcao repassada para caso de sucesso
    onSuccess(resp.data);
  } catch (error: any) {
    if (error?.response?.data?.message) {
      ToastError(error?.response?.data?.message);
    } else if (error?.message) {
      ToastError(error?.message);
    } else {
      ToastError(String(error));
    }
    return false;
  } finally {
    if (onWait) onWait(false);
  }
}
