import { Dispatch } from "react";
import fetchRedux from "../../api/fetch_redux";
import { toast } from "react-toastify";
import { AnyAction } from "redux";

const ROUTES = [
    '/pedidos',
    '/pedidos_nota_fiscal',
]

export const HOME_INIT = 'HOME_INIT';
export const HOME_SET_MODAL = 'HOME_SET_MODAL';
export const HOME_CLOSE_MODAL = 'HOME_CLOSE_MODAL';

export const homeInitAction = (onWait: Function) => (dispatch: Dispatch<HomeReduxAction>) => {

    fetchRedux(
        ROUTES[0],
        'GET', null, (response: HomeReduxState)=>{
            dispatch({
                type: HOME_INIT,
                data: response
            });
        },
        onWait,
    );
}

export const homeAddNF = (data: any, onWait: Function) => (dispatch: Dispatch<AnyAction>) => {
    fetchRedux(
        ROUTES[1],
        'POST', data, (response: any)=>{
            toast.dark(response['sucesso'], {
                type: 'success'
            });
            dispatch(homeCloseModal());
        },
        onWait,
    );
}
// Determina o modal a ser ativado
export const homeSetModal = (data: Object)=> ({
    type: HOME_SET_MODAL,
    data: data,
})

// Limpa o modal da tela
export const homeCloseModal = ()=>({
    type: HOME_CLOSE_MODAL,
});