import { MutableRefObject, useCallback, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA as KEY } from '../config_key';
import { Button, CircularProgress, Divider, Grow, IconButton, Paper, Stack, TextField, useTheme } from '@mui/material';
import { useToggle } from 'react-use';
import axios, { AxiosError } from 'axios';
import { Body1 } from '../components/typograph';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Logo from '../components/logo';
import { ToastError } from '../utils';
import Icon from '../components/icon';

const APP_DEBUG = window.location.hostname.search("localhost") !== -1;

const validatorForm = yup.object().shape({
    usuario: yup.string().min(1).required(),
    senha: yup.string().min(1).required(),
});

const STR = {
  labelCompany: "Portal da Logística",
  labelBtnLogin: "Acessar",
  placeholderPassword: "Digite a sua senha",
  placeholderName: "Digite o seu nome de acesso",
  labelFieldName: "LOGIN",
  labelFieldPassword: "SENHA",
  errorUsername: "* O nome de usuário não foi digitado",
  errorPassword: "* A senha não foi digitada",
  internalError: "* Erro interno do servidor. Se persistir contacte o admin",
}

interface FormLoginProps {
    recaptchaRef: MutableRefObject<ReCAPTCHA | null>;
}

interface ResultSubmit {
    usuario: string;
    senha: string;
}

interface FormFieldProps {
    name: "usuario" | "senha";
    label: string;
    type: "text" | "password";
    placeholder: string;
    error: string;
    prefixIcon: JSX.Element;
    suffixIcon?: JSX.Element;
}

function Login() {
    // Para referenciar o captcha invisivel
    const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  return (
    <Stack sx={{height: '100vh', background: ({ palette }) => palette.primary.light}} 
        alignItems='center' justifyContent='center'
    >
    <FormLogin recaptchaRef={recaptchaRef} />
    <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={KEY}
        />
    </Stack>
  )
}

const FormLogin = ({ recaptchaRef }: FormLoginProps) => {
    const colorPrimary = useTheme()?.palette?.primary?.main;
    const [wait, setWait] = useToggle(false);
    const [viewPassword, setViewPassword] = useToggle(false);
    const {
      handleSubmit,
      control,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(validatorForm),
    });
    //
    const sxPaper = {
      p: 1,
      pb: 2,
      background: "white",
      color: "black",
      width: {
        xs: "calc(100vw - 32px)",
        md: "60%",
        lg: "40%",
        xl: "30%",
      },
    };
    //
    const onSubmit = useCallback(
      async (val: ResultSubmit) => {
        const { usuario, senha } = val;
        setWait();
        const formData = new FormData();
        //
        if (!APP_DEBUG) {
            if(recaptchaRef.current){
                recaptchaRef.current.reset();
                // Pega o token do recaptcha
                let TOKEN = recaptchaRef.current.getValue();
                TOKEN = await recaptchaRef.current.executeAsync();
                formData.append("captcha", TOKEN || "");
            }
        }
        // Preenchendo os campos necessarios para login
        formData.append("username", usuario);
        formData.append("password", senha);
        try {
            await axios.post("/", formData);
        // Tudo certo redirecione o usuario
        window.location.reload();
            
        } catch (error) {
            if(error instanceof AxiosError){
                if(error.response?.data){
                    ToastError(error.response.data);
                } else {
                    ToastError(String(error));
                }
            } else {
                ToastError(String(error));
            }
        } finally {
            setWait();
        }
      },
      [recaptchaRef, setWait]
    );
    //
    const schemaFormFields: Array<FormFieldProps> = [
      {
        name: "usuario",
        label: STR.labelFieldName,
        type: "text",
        placeholder: STR.placeholderName,
        prefixIcon: <Icon sx={{ mr: 1, color: colorPrimary }} icon="Person" />,
        error: STR.errorUsername,
      },
      {
        name: "senha",
        label: STR.labelFieldPassword,
        type: viewPassword ? "text" : "password",
        placeholder: STR.placeholderPassword,
        error: STR.errorPassword,
        prefixIcon: <Icon sx={{ mr: 1, color: colorPrimary }} icon="VpnKey" />,
        suffixIcon: (
          <IconButton onClick={setViewPassword}>
            <Icon
              icon={viewPassword ? "Visibility" : "VisibilityOff"}
              sx={{ color: colorPrimary }}
            />
          </IconButton>
        ),
      },
    ];
    //
    return (
      <Grow in>
        <Paper sx={sxPaper} elevation={3}>
          <Stack gap={2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Logo />
              <Stack direction='row' gap={1} alignItems='center'>
                <Body1
                    sx={{
                    fontSize: {
                        xs: "1rem",
                        md: "1.25rem",
                        lg: "1.5rem",
                    },
                    color: "black",
                    fontFamily: "Caveat",
                    }}
                >
                    {STR.labelCompany}
                </Body1>
                <Icon sx={{fontSize: 36}} icon='LocalShipping' />
              </Stack>
              
            </Stack>
            <Divider />
            {schemaFormFields.map((fieldItem, idx) => (
              <Controller
                key={fieldItem.name}
                name={fieldItem.name}
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={wait}
                    label={fieldItem.label}
                    placeholder={fieldItem.placeholder}
                    type={fieldItem.type}
                    variant="standard"
                    autoFocus={idx === 0}
                    InputProps={{
                      startAdornment: fieldItem.prefixIcon,
                      endAdornment: fieldItem.suffixIcon,
                    }}
                    InputLabelProps={{
                      color: "primary",
                      sx: {
                        color: "black",
                      },
                      shrink: true,
                    }}
                    inputProps={{
                      style: {
                        color: "black",
                      },
                    }}
                    helperText={
                      errors && errors[fieldItem.name] && fieldItem.error
                    }
                    error={errors && errors[fieldItem.name] ? true : false}
                  />
                )}
              />
            ))}
  
            <Button
              disabled={wait}
              startIcon={wait ? <CircularProgress size={20} /> : null}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
            >
              {STR.labelBtnLogin}
            </Button>
          </Stack>
        </Paper>
      </Grow>
    );
  };

export default Login