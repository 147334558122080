import React from 'react'
import NFInItem from './nf-in-item';
import ContainerAdaptable from '../../components/container-adaptable';

interface ModalOrderProps {
    modal: Modal,
}

function ModalOrder({ modal }: ModalOrderProps) {
    let body = null;
    switch(modal.type){
        case ModalOrder.modal.ADD_NF:
            body = <NFInItem item={modal.data as ItemOrder} />;
            break;
        default:
            break;
    }

    return (
        <ContainerAdaptable sx={{minHeight: '50vh'}}>
            {body}
        </ContainerAdaptable>
    )
}

ModalOrder.modal = {
    ADD_NF: 'ADD_NF',
}

export default ModalOrder