import React, { memo } from 'react'
import { Slide, Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';

interface DialogViewProps {
    fecharDialogo: VoidFunction;
    corpo: JSX.Element | null;
    larguraMaxima?: boolean;
    fullTela?: boolean;
    sxContent: Object;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
// Componente Dialog para outros tipos de conteudo
const DialogView = ({fecharDialogo, corpo, larguraMaxima, fullTela, sxContent}: DialogViewProps)=>{

    return (
        <Dialog open={Boolean(corpo)} 
            fullWidth={larguraMaxima}
            maxWidth='lg'
            fullScreen={fullTela}
            TransitionComponent={Transition}
        >
            
            <DialogContent
                sx={sxContent}
            >
                {corpo}
            </DialogContent>
            <DialogActions>
                <Button startIcon={<CloseIcon />} onClick={fecharDialogo}>Fechar</Button>
            </DialogActions>

        </Dialog>
    )
}
DialogView.defaultProps = {
    sxContent: {}
}

export default memo( DialogView );