import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { ToastError } from '../../utils';
import { useToggle } from 'react-use';
import { Button, Container, Stack } from '@mui/material';
import { H6 } from '../../components/typograph';
import Icon from '../../components/icon';
import RowPaginateTemplate, { Row } from '../../templates/row_paginate_template';
import { useDispatch, useSelector } from 'react-redux';
import { homeCloseModal, homeSetModal } from './home-action';
import ModalOrder from './modal-order';
import { selectorModal } from './selectors';
import DrawerDialog from '../../components/drawer-dialog';

interface ItemsInOrderRequest {
    itens: Array<ItemOrder>;
}

interface ItemsInOrderProps {
    order?: Order;
}

const STR = {
    labelGoBack: 'Retornar',
    title: 'Itens do pedido',
    labelInvoice: 'Nota Fiscal',
    labelNumItem: 'Item',
    labelCode: 'Código',
    labelDescription: 'Descrição',
    labelQuantity: 'Quantidade',
    labelDelivery: 'Entregue',
    labelPrevist: 'Previsto',
}

function ItemsInOrder({ order }: ItemsInOrderProps) {
    const [wait, setWait] = useToggle(false);
    const [items, setItems] = useState<Array<ItemOrder>>([]);
    const state = useLocation<string>().state;
    //
    const dispatch = useDispatch();
    const modal = useSelector(selectorModal);
    const onCloseModal = useCallback(()=>{
      dispatch(homeCloseModal());
    }, [dispatch]);

    useEffect(()=>{
        if(state || order){
            (async ()=>{
                const orderNum = state || order?.numero;
                try {
                    setWait();
                    const response = await axios.get<ItemsInOrderRequest>(`/pedido_itens/${orderNum}`);
                    setItems(response.data.itens);
                    
                } catch (error: any) {
                    if (error?.response?.data?.message) {
                        ToastError(error?.response?.data?.message);
                      } else if (error?.message) {
                        ToastError(error?.message);
                      } else {
                        ToastError(String(error));
                      }
                } finally {
                    setWait();
                }

            })();
        }

    }, [setItems, setWait, state, order]);

    let numberOrder: string | null = null;
    if(state !== undefined){
        numberOrder = state;
    } else if(order !== undefined){
        numberOrder = order.numero;
    }
    
  return (
    <>
    {modal?.data && (
      <DrawerDialog fecharModal={onCloseModal} fnGetCorpo={()=> <ModalOrder modal={modal} />} />
      )}
      <OrderViewTable numberOrder={numberOrder} items={items} wait={wait} />
    </>
  )
}

type NumberOrderProps = {
    numberOrder: string | null,
}

interface OrderViewProps extends NumberOrderProps{
    items: Array<ItemOrder>;
    wait: boolean;
}

const OrderViewTable = ({ numberOrder, items, wait}: OrderViewProps)=>{
    const dispatch = useDispatch();
    // Exibe as notas fiscais atreladas ao item
    const onViewNF = useCallback((itemNF: ItemOrder)=>{
        dispatch(homeSetModal({
            type: ModalOrder.modal.ADD_NF,
            data: itemNF
        }));
    }, [dispatch])

    const rowsTable: Array<Row> = [];
    const headerTable: Array<string> =[
        STR.labelInvoice,
        STR.labelNumItem,
        STR.labelCode,
        STR.labelDescription,
        STR.labelQuantity,
        STR.labelDelivery,
        STR.labelPrevist,
    ];

    items?.forEach(item=>{
        const row = {
            onClick: ()=>{},
            [STR.labelInvoice]: <Button onClick={()=> onViewNF(item) }>VER NFs</Button>,
            [STR.labelNumItem]: item.item,
            [STR.labelCode]: item.codigo,
            [STR.labelDescription]: item.descricao,
            [STR.labelQuantity]: item.quantidade,
            [STR.labelDelivery]: item.entregue,
            [STR.labelPrevist]: item.previsto,
        };

        rowsTable.push(row);
    })

    return (
        <Container maxWidth='lg'>
            <RowPaginateTemplate
                headerAboveTable={<TableHeaderView numberOrder={numberOrder} />}
                titlePage=''
                rows={rowsTable}
                header={headerTable}
                />
        </Container>
    )
}

const TableHeaderView = ({numberOrder}: NumberOrderProps)=>{
    const history = useHistory();

    return (
        <Stack direction='row'>
            <Button startIcon={<Icon icon='Undo' />} onClick={history.goBack}>
              {STR.labelGoBack}
            </Button>
            <H6 sx={{flex: 1}}>{STR.title} {numberOrder}</H6>
        </Stack>
    )
}

ItemsInOrder.route = '/pedido_itens';

export default ItemsInOrder