import { UserReduxAction } from "../type";
import { USER_INIT } from "../actions/user-action";

export default function userReducer(state = null, action: UserReduxAction) {

    switch(action.type){
        case USER_INIT:
            return action.data;
        default:
            return state;

    }

}