import { HOME_CLOSE_MODAL, HOME_INIT, HOME_SET_MODAL } from "./home-action";

export default function homeReducer(state: HomeReduxState | null = null, action: HomeReduxAction) {
    switch(action.type){
        case HOME_INIT:
            return action.data;
        case HOME_SET_MODAL:
            return {
                ...state,
                modal: action.data,
            }
        case HOME_CLOSE_MODAL:
            return {
                ...state,
                modal: null
            }
        default:
            return state;
    }
}