import { AppBar, Box, IconButton, Toolbar, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import Logo from './logo'
import { Body1 } from './typograph'
import OptionsMenu, { OptionsMenuItem } from './options-menu'
import { ThemeOptionsProps } from '../types'
import Icon from './icon'
import { useHistory } from 'react-router-dom'

const STR = {
    title: 'Logística',
    titleOptions: 'Exibir Opções',
    labelPasswd: 'Mudar Senha',
    labelLogout: 'Sair do App',
}

function Appbar() {
    const isMobile = useTheme<ThemeOptionsProps>()?.isMobile;
    const history = useHistory();
    const [viewBtnGoBack, setViewBtnGoBack] = useState<boolean>(false);
      // Altera entre exibir e ocultar o botao de volta
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (history.action === "PUSH") {
        setViewBtnGoBack(true);
      } else if (history.action === "POP") {
        setViewBtnGoBack(false);
      }
    });
    return unlisten;
  }, [setViewBtnGoBack, history]);

    const optionsMenu: Array<OptionsMenuItem> = [
        {
            icon: 'Build',
            label: STR.labelPasswd,
            onClick: ()=>{},
        },
        {
            icon: 'Logout',
            label: STR.labelLogout,
            onClick: ()=>{
                window.location.href = '/logout';
            },
        },
    ];
  return (
    <Box  sx={{flexGrow: 1}}>
    <AppBar color='primary' position='static'>
        <Toolbar sx={{alignItems: 'center'}}>
            {viewBtnGoBack && isMobile ? <BtnGoBack /> : <Box sx={{width: 40, height: 40}} />}
            <Logo isBlank style={{height: 24}} />
            <Body1 sx={{flexGrow: 1, fontFamily: 'Caveat', fontSize: '1.25rem'}} align='right'>{STR.title}</Body1>
            <OptionsMenu sx={{color: 'white'}} options={optionsMenu} icon='MoreVert' title={STR.titleOptions} />
        </Toolbar>
    </AppBar>
    </Box>
  )
}

const BtnGoBack = ()=>{
    const history = useHistory();
    const onGoBack = useCallback(()=>{
        history.goBack();
    }, [history]);


    return (
        <IconButton sx={{color: 'white'}} onClick={onGoBack}>
            <Icon icon='Undo' />
        </IconButton>
    )

}

export default Appbar