import { SyntheticEvent, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Body2 } from "./typograph";
import Icon from "./icon";
import { IconButton, Menu, MenuItem, Stack, SxProps } from "@mui/material";

export interface OptionsMenuItem {
  icon: string;
  label: string;
  onClick: Function;
}

interface OptionsMenuProps {
  title: string;
  icon: string;
  options: Array<OptionsMenuItem>;
  sx?: SxProps;
}

function OptionsMenu({ title, icon, options, sx }: OptionsMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onViewMenu = useCallback(
    (e: SyntheticEvent<HTMLButtonElement> ) => {
      setAnchorEl(e.currentTarget);
    },
    [setAnchorEl]
  );

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const onClickItem = useCallback(
    (onClick: Function) => {
      onClick();
      closeMenu();
    },
    [closeMenu]
  );

  return (
    <>
      <IconButton sx={{...sx}} title={title} onClick={onViewMenu}>
        <Icon icon={icon} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
        {options.map((ele, idx) => (
          <MenuItem key={idx} onClick={() => onClickItem(ele.onClick)}>
            <Stack direction="row" gap={1}>
              <Icon icon={ele.icon} />
              <Body2>{ele.label}</Body2>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

OptionsMenu.defaultProps = {
  icon: "MoreVert",
  title: "Clique para ver as opções",
};

OptionsMenu.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default OptionsMenu;
