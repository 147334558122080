import { Paper, ThemeProvider, createTheme, useMediaQuery, useTheme } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { ComponentType, useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Login from './routes/Login';
import Home from './routes/Home';
import Splash from './routes/Splash';
import { ThemeOptionsProps } from './types';
import Appbar from './components/Appbar';
import ItemsInOrder from './routes/Home/items_in_order';

const routesWithoutAppBar = [
  '/', '/splash'
];

interface RoutesProps {
  path: string;
  component: ComponentType;
}

const routes: Array<RoutesProps> = [
  {
    path: '/',
    component: Login,
  },
  {
    path: Home.route,
    component: Home,
  },
  {
    path: Splash.route,
    component: Splash,
  },
  {
    path: ItemsInOrder.route,
    component: ItemsInOrder
  }
];

function App() {
  //
  const history = useHistory();
  const [routesDefault, setRoutesDefault] = useState(window.location.pathname);
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const darkMode = false;
    // Ouvir alteracoes na navegacao para setar o menu selecionado corretamente
    useEffect(() => {
      const unlisten = history.listen(() => {
        const { location } = history;
        setRoutesDefault(location.pathname);
      });
      return unlisten;
    }, [history, setRoutesDefault]);

  const theme = createTheme({
    isMobile: isMobile,
    backgroundPage: !darkMode && grey[200],
    palette: {
      mode: darkMode ? "dark" : "light",
      primary: darkMode
        ? {
            main: blue["A200"],
            dark: "#012258",
          }
        : {
            main: "#005387",
            dark: "#012258",
          },
      secondary: {
        main: "#9c27b0",
      },
    },
  } as ThemeOptionsProps);

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer position="bottom-center" />
      <Paper elevation={0} style={{ borderRadius: 0, minHeight: "100vh" }}>
        {!routesWithoutAppBar.includes(routesDefault) && <Appbar />}
        {routes.map((ele, idx) => (
          <Route key={idx} exact={true} path={ele.path} component={ele.component} />
        ))}
        {isMobile && (
          <>
            <br />
            <br />
            <br />
          </>
        )}
        
      </Paper>
    </ThemeProvider>
  );
}

export default App;
