import React, { memo } from 'react';
import { Typography, TypographyProps } from '@mui/material';


export const H6 = memo( (props: TypographyProps)=>(
    <Typography variant='h6' align='center' {...props}>
        {props.children}
    </Typography>
) )

export const H5 = memo( (props: TypographyProps)=>(
    <Typography variant='h5' align='center' {...props}>
        {props.children}
    </Typography>
) )
export const H4 = memo( (props: TypographyProps)=>(
    <Typography variant='h4' align='center' {...props}>
        {props.children}
    </Typography>
) )
export const H3 = memo( (props: TypographyProps)=>(
    <Typography variant='h3' align='center' {...props}>
        {props.children}
    </Typography>
)) 
export const H2 = memo( (props: TypographyProps)=>(
    <Typography variant='h2' align='center' {...props}>
        {props.children}
    </Typography>
) )
export const H1 = memo( (props: TypographyProps)=>(
    <Typography variant='h1' align='center' {...props}>
        {props.children}
    </Typography>
))

export const Subtitle1 = memo( (props: TypographyProps)=>(
    <Typography variant='subtitle1' align='center' {...props}>
        {props.children}
    </Typography>
) )

export const Subtitle2 = memo( (props: TypographyProps)=>(
    <Typography variant='subtitle2' align='center' {...props}>
        {props.children}
    </Typography>
) )

export const Caption = memo( (props: TypographyProps)=>(
    <Typography variant='caption' align='center' {...props}>
        {props.children}
    </Typography>
) )

export const Body1 = memo( (props: TypographyProps)=>(
    <Typography variant='body1' {...props}>
        {props.children}
    </Typography>
) )

export const Body2 = memo( (props: TypographyProps)=>(
    <Typography variant='body2' {...props}>
        {props.children}
    </Typography>
))