import React, { memo } from 'react'
import { Paper, Box, styled, Drawer, SwipeableDrawer } from '@mui/material';

interface DeslizavelProps {
    corpo: JSX.Element | null;
    fecharDrawer: VoidFunction;
}

interface DrawerViewProps {
    fecharDrawer: VoidFunction;
    corpo: JSX.Element | null;
    isDeslizavel: boolean;
}

//
const Puller = styled(Box)(({ theme }) => ({
    width: 40,
    height: 8,
    backgroundColor:  theme.palette.primary.main,
    borderRadius: 4,
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 'calc(50% - 20px)',
  }));
  

const Deslizavel = ({ corpo, fecharDrawer }: DeslizavelProps)=>(
    <SwipeableDrawer
        anchor='bottom'
        open={Boolean(corpo)}
        onClose={fecharDrawer} 
        onOpen={()=>{}}    >
        <Paper elevation={1} sx={{ borderRadius: 0, mb: 1, zIndex: 1000, position: 'sticky', top: 0, }}>
            <Puller />
        </Paper>
      {corpo}
    </SwipeableDrawer>
)
// Componente para exibir o Drawer de exibicao. Para mobile
const DrawerView = ({ fecharDrawer, corpo, isDeslizavel }: DrawerViewProps)=>{

    return isDeslizavel ? (
        <Deslizavel corpo={corpo} fecharDrawer={fecharDrawer} /> 
        ) : (
        <Drawer anchor='bottom' open={Boolean(corpo)} onClose={fecharDrawer}>
            {corpo}
        </Drawer>
        )
}
//
DrawerView.defaultProps = {
    corpo: null,
    isDeslizavel: true,
}


export default memo( DrawerView );