import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getDataUserAction } from '../redux/actions/user-action';
import { Stack } from '@mui/material';
import { AnimationTruck } from '../components/lotties';
import Logo from '../components/logo';
import { Body2 } from '../components/typograph';
import { useHistory } from 'react-router-dom';

const STR = {
    description: 'Aguarde, carregando os dados iniciais',
}

const selectorUser = (state: any) => state?.user;

function Splash() {
    const history = useHistory();
    const user = useSelector(selectorUser);
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch<any>(getDataUserAction());
    }, [dispatch]);

    useEffect(()=>{
        if(user){
            setTimeout(() => {
                history.push('/home');
            }, 6e3);
        }

    }, [user, history]);
  return (
    <Stack alignItems='center' justifyContent='center' sx={{
        height: '100vh',
        background: ({ palette }) => palette.primary.main
    }}>
        <Logo isBlank />
        <AnimationTruck />
        <Body2 sx={{color: 'white'}}>{STR.description}</Body2>
    </Stack>
  )
}

Splash.route = '/splash';


export default Splash