import { Box, Button, CircularProgress,  Grid,  Grow,  Paper,  Stack, TextField } from '@mui/material';
import { useCallback, useEffect } from 'react'
import { Caption, H6 } from '../../components/typograph';
import useFetch from '../../hooks/use-fetch';
import { ToastError } from '../../utils';
import RowPaginateTemplate, { Row } from '../../templates/row_paginate_template';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useToggle } from 'react-use';
import { yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import MaskedInput from 'react-text-mask';
import { homeAddNF } from './home-action';
import Icon from '../../components/icon';

const ROUTES = [
  '/pedidos_nota_fiscal',
];

const NFInItemSTR = {
  title: 'NFs para o item ',
  labelNumber: 'Pedido',
  labelItem: 'Item',
  labelInvoice: 'Nota fiscal',
  labelSerie: 'Série',
  labelQuantity: 'Quantidade',
  labelStatus: 'Situação',
  labelButtonSave: 'SALVAR',
  labelBtnView: 'Ver Form',
  labelBtnInvisible: 'Ocultar Form',
  errorInvoice: '* A nota fiscal deve ter 9 digitos',
  errorSerie: '* A série deve ter entre 1 e 4 digitos',
  errorQuantity: '* A quantidade informada deve ser maior que zero',
}

const nameFields = {
  invoice: 'nota_fiscal',
  quantity: 'quantidade',
  serie: 'serie',
}


interface NFInItemProps {
  item: ItemOrder;
}

function NFInItem({item}: NFInItemProps) {
  const [viewForm, setViewForm] = useToggle(true);
  
  return (
    <Stack alignItems='center'>
      <H6>{NFInItemSTR.title} - {item.descricao}</H6>
      <Button variant='outlined' onClick={setViewForm}>{viewForm ? NFInItemSTR.labelBtnView : NFInItemSTR.labelBtnInvisible}</Button>
      <Grow in={viewForm} unmountOnExit>
        <Box>
      <FormAddNF item={item} />

        </Box>
      </Grow>
      <NFToItem item={item} />
    </Stack>
  )
}
//
const FormAddNF = ({ item }: NFInItemProps)=>{
  const dispatch  = useDispatch();
  const [wait, setWait] = useToggle(false);
  const validatorFormAddNF = yup.object().shape({
    [nameFields.serie]: yup.string().min(1).max(4).required(),
    [nameFields.quantity]: yup.number().min(1).required(),
});
//
const errorsForm = {
  [nameFields.invoice]: NFInItemSTR.errorInvoice,
  [nameFields.serie]: NFInItemSTR.errorSerie,
  [nameFields.quantity]: NFInItemSTR.errorQuantity,
}

  const {control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validatorFormAddNF),
  });
  const mask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

  const onSubmit = useCallback((val: any)=>{
    const invoice =  (val[nameFields.invoice] as string).padStart(9, '0');
    const serie = val[nameFields.serie];
    const quantity = val[nameFields.quantity];

    const numOrderItem = `${item.pedido}_${item.item}`;
    const invoice_serie = `${invoice}_${serie}`;

    dispatch<any>(
      homeAddNF({
        "num_pedido_item": numOrderItem,
        "nota_fiscal_serie": invoice_serie,
        "quantidade": quantity
      }, 
      setWait)
    );


  }, [dispatch, setWait, item]);


  return (
    <Paper>
    <Stack sx={{p: 1}} alignItems='center'>
      <Grid sx={{my: 2}} container spacing={1}>
        <Grid item xs={12} md={4}>
          <Controller
            name={nameFields.invoice}
            control={control}
            defaultValue=''
            rules={{ required: true }}
            render={({ field })=>(
              <MaskedInput
                  mask={mask}
                  guide={false}
                  {...field}
                  render={(ref, props) => (
                    <TextField
                    {...props} 
                    
                        inputRef={ref}
                        fullWidth
                        label={NFInItemSTR.labelInvoice}
                        variant='filled'
                        type='text'
                        InputLabelProps={{ shrink: true }}
                        error={!!errors.hasOwnProperty(nameFields.invoice)}
                        helperText={
                          <Caption color='error'>
                            {errors && errors.hasOwnProperty(nameFields.invoice) ? errorsForm[nameFields.invoice] : null}
                          </Caption>
                        }
                  />
                )}
              />
            )}
            />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name={nameFields.serie}
            control={control}
            render={({ field })=>(
              <TextField
                fullWidth
                label={NFInItemSTR.labelSerie}
                variant='filled'
                type='text'
                helperText={
                  <Caption color='error'>
                    {errors && errors.hasOwnProperty(nameFields.serie) ? errorsForm[nameFields.serie] : null}
                  </Caption>
                }
                {...field}
              />
            )}
            />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name={nameFields.quantity}
            control={control}
            render={({ field })=>(
              <TextField
                fullWidth
                label={NFInItemSTR.labelQuantity}
                variant='filled'
                type='text'
                helperText={
                  <Caption color='error'>
                    {errors && errors.hasOwnProperty(nameFields.quantity) ? errorsForm[nameFields.quantity] : null}
                  </Caption>
                }
                {...field}
              />
            )}
            />
        </Grid>
      </Grid>
      <Button disabled={wait} startIcon={wait ? <CircularProgress size={20} /> : <Icon icon='Save' />} variant='contained' onClick={handleSubmit(onSubmit)}>{NFInItemSTR.labelButtonSave}</Button>

    </Stack>
    </Paper>
  )
}
//
const NFToItem = ({item}: NFInItemProps) => {
  const { data, wait, setFetch, error } = useFetch(`${ROUTES[0]}/${item.pedido}_${item.item}`,'GET');

  useEffect(()=>{
    setFetch({});
  }, [setFetch]);
  //
  useEffect(()=>{
    if(error) ToastError(error);

  }, [error]);

  const dataArray: Array<InvoiceItem> = [];
  if(data && Array.isArray(data)){
    const arrayDataResult: Array<object> = data;
    arrayDataResult.forEach(element => {
      dataArray.push(element as InvoiceItem);
    });

  }

  return (
    <Stack sx={{mt: 1}}>
      {wait ? <CircularProgress /> : <NFList data={dataArray} />}
    </Stack>
  )
}

interface NFListProps {
  data: Array<InvoiceItem>;
}
//
const NFList = ({ data }: NFListProps)=>{
  const rowsTable: Array<Row> = data.map(item=> ({
    onClick: ()=>{},
            [NFInItemSTR.labelNumber]: item.numero,
            [NFInItemSTR.labelItem]: item.item,
            [NFInItemSTR.labelInvoice]: item.nota_fiscal,
            [NFInItemSTR.labelSerie]: item.serie,
            [NFInItemSTR.labelQuantity]: item.quantidade,
            [NFInItemSTR.labelStatus]: item.situacao,
  }));
  const headerTable: Array<string> = [
      NFInItemSTR.labelNumber,
      NFInItemSTR.labelItem,
      NFInItemSTR.labelInvoice,
      NFInItemSTR.labelSerie,
      NFInItemSTR.labelQuantity,
      NFInItemSTR.labelStatus,
];


  return (
    <RowPaginateTemplate
        hiddenPaginate={true}
        titlePage=''
        rows={rowsTable}
        header={headerTable}
     />
  )
}

export default NFInItem