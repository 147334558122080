import { Dispatch } from "react";
import { UserReduxAction, UserReduxState } from "../type";
import fetchRedux from "../../api/fetch_redux";

export const USER_INIT = 'USER_INIT';

export const getDataUserAction = () => (dispatch: Dispatch<UserReduxAction>) => {
    
    fetchRedux(
        '/get_data_user',
        'GET',
        null,
        (response)=>{
            console.log(response);
            dispatch({
                type: USER_INIT,
                data: response as UserReduxState
            });
        },
        null,
    );
}