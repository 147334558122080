import { Container } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { selectorOrder } from './selectors';
import { useCallback, useEffect } from 'react';
import { useToggle } from 'react-use';
import { homeInitAction } from './home-action';
import { useHistory } from 'react-router-dom';
import ItemsInOrder from './items_in_order';
import RowPaginateTemplate, { Row } from '../../templates/row_paginate_template';


const STR = {
  titleOrder: 'Pedido N°',
  title: 'Lista de pedidos de compra',
  titleIndicator: 'Clique em algum pedido de compra',
  labelHeader1: 'Emissão',
  labelHeader2: 'Fornecedor',
  labelHeader3: 'Loja',
  labelHeader4: 'N° Pedido',
  labelHeader5: 'Situação',
}

function Home() {
  return (
    <ViewTable />
  )
}


//
const ViewTable = ()=>{
  const history = useHistory();
  const [ , setWait] = useToggle(false);
  const dispatch = useDispatch();
  const orders = useSelector(selectorOrder);
  
  useEffect(()=>{
    dispatch<any>(homeInitAction(setWait));

  }, [setWait, dispatch])
  
  const headerTable = [
    STR.labelHeader2,
    STR.labelHeader3,
    STR.labelHeader4,
    STR.labelHeader5,
    STR.labelHeader1,
  ]

  const onViewItems = useCallback((order: Order)=>{
    
      history.push(ItemsInOrder.route, order.numero);

  }, [history]);

  const rowsTable: Array<Row> = [];
  
  orders?.forEach(item=>{
    const row = {
      onClick: ()=> onViewItems(item),
      [STR.labelHeader2]: item.fornecedor,
      [STR.labelHeader3]: item.loja,
      [STR.labelHeader4]: item.numero,
      [STR.labelHeader5]: item.situacao,
      [STR.labelHeader1]: item.emissao,
    };

    rowsTable.push(row);
  });

  return (
    <Container maxWidth='lg'>
      <RowPaginateTemplate
        titlePage={STR.title}
        header={headerTable}
        rows={rowsTable}
      />
      </Container>

  )
}

Home.route = '/home';

export default Home