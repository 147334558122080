import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
// Supports weights 400-700
import '@fontsource/caveat/500.css';

// Provider e connect e também a loja (store)
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";

// Importar o redux-thunk
import reduxThunk from "redux-thunk";

// O devTools
//import { composeWithDevTools } from 'remote-redux-devtools';

// Importando o rootReducer
import rootReducer from "./redux/reducers";

// --> PROD
const store = createStore(rootReducer, applyMiddleware(reduxThunk));


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
