import { Container, useTheme } from "@mui/material";
import Lottie from "react-lottie";
import dataLottie from "../lotties/animation_truck.json";
import dataLottie2 from '../lotties/indicator_hand.json';
import { Body2 } from "./typograph";
import { ThemeOptionsProps } from "../types";

const defaultOptions = {
  loop: true,
  autoplay: true,
  delay: 2000,
  animationData: dataLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface AnimationProps {
    title?: string;
    width?: number;
    height?: number;
}

export const AnimationTruck = ({ title, width, height }: AnimationProps) => {
    const isMobile = useTheme<ThemeOptionsProps>()?.isMobile;

  return (
    <Container disableGutters maxWidth="xs" sx={{ p: 1, mt: 2 }}>
      <Lottie
        options={{ ...defaultOptions, animationData: dataLottie }}
        height={width || !isMobile ? 400 : 250}
        width={height || !isMobile ? 400 : 250}
      />
      {title && (
        <Body2 component="p" align="center">
          {title}
        </Body2>
      )}
    </Container>
  );
};

export const AnimationIndicatorHand = ({ title, width, height }: AnimationProps) => {
  const isMobile = useTheme<ThemeOptionsProps>()?.isMobile;

return (
  <Container disableGutters maxWidth="xs" sx={{ p: 1, mt: 2 }}>
    <Lottie
      options={{ ...defaultOptions, animationData: dataLottie2 }}
      height={width || !isMobile ? 400 : 250}
      width={height || !isMobile ? 400 : 250}
    />
    {title && (
      <Body2 component="p" align="center">
        {title}
      </Body2>
    )}
  </Container>
);
};