import * as MuiIcons from '@mui/icons-material';
import { SxProps } from '@mui/material';
import React from 'react';

interface IconProps {
    icon: any;
    sx?: SxProps;
}

export default function Icon(props: IconProps){
    // @ts-ignore
    const IconView = MuiIcons[props.icon];
    if(!IconView) return <p>None</p>;

    return React.createElement(IconView, {...props});
}